<template>
  <member-slack-id-change
    :show.sync="setShow"
    :email="gardener.email"
    :id="gardener.id"
    :role="'gardener'"
    :slack-id="gardener.slack_id"
    @success="emitUpdate"
  />
</template>

<script>
export default {
  name: "GardenerSlackIdChange",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    gardener: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    MemberSlackIdChange: () =>
      import("@/components/Settings/Members/Member/MemberSlackIdChange"),
  },
  data() {
    return {};
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    emitUpdate(id) {
      this.$emit("success", id);
    },
  },
};
</script>
